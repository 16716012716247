import { GoogleApiWrapper, InfoWindow, Map as GMaps, Marker, Polyline } from 'google-maps-react';
import queryString from 'query-string';
import React from 'react';
import * as traceability_service from '../../domain/services/traceability_service';
import logo from '../img/hortify-preto.png';
import './App.css';

class ProducerTraceability extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            product: null,
            retail: '',
            retailsList: null,
            retailsSelectList: null,
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},
            initialCenter: null,
            showForm: false,
            showRetails: false,
            traceabilityCode: ''
        };
    }

    componentDidMount() {
        const search = queryString.parse(this.props.search)
        this.loadProcuderData(search);
    }

    render() {
        const showForm = this.state.showForm;
        const showRetails = this.state.showRetails;
        const retailsSelectList = this.state.retailsSelectList;
        const productTraceability = this.state.product;
        const search = queryString.parse(this.props.search)
        const isUrlOk = search.u != null;
        const isLoading = productTraceability === null;

        if (!isUrlOk)
            return <p>Url inválida</p>

        if (isLoading && !showForm && !showRetails)
            return <p>Carregando...</p>

        if (showForm) {
            return this.traceabilityCodeField()
        }

        if (showRetails && retailsSelectList != null) {
            return this.retailsSelectOptions(retailsSelectList)
        }

        if (!isLoading && productTraceability.cropId != null)
            return (
                <div className="container">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">
                                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
                            </h3>
                        </div>

                        {this.cultiveComponents(productTraceability)}

                        <div className="row" style={{ marginTop: 1.5 + 'em' }}>
                            {this.producerComponents(productTraceability)}
                            {this.customerComponents(productTraceability)}
                        </div>

                        {this.displayMaps()}
                        {this.displayAppointments()}
                    </div>
                </div>
            );
        else
            return <p>Código inválido!</p>
    }

    traceabilityCodeField = () => {
        return <div style={{ marginTop: 5.5 + 'em' }}>
            <div className="text-center">
                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
            </div>

            <div className="text-center">
                <label>
                    Por favor informe o código de rastreabilidade localizado na embalagem
          </label>
            </div>
            <div className="row justify-content-center m-top">
                <div className="col-sm-4">
                    <input className="form-control" value={this.state.traceabilityCode} onChange={this.handleTraceabilityCodeChange} type="text" />
                </div>
            </div>

            <div className="text-center m-top">
                <input type="button" className="btn btn-success" onClick={this.handleSubmit} value="Buscar" />
            </div>
        </div>
    }

    retailsSelectOptions = (retailsSelectList) => {
        return <div style={{ marginTop: 5.5 + 'em' }}>
            <div className="text-center">
                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
            </div>

            <div className="text-center">
                <label>
                    Por favor selecione o local onde o produto foi adquirido
                </label>
            </div>
            <div className="row justify-content-center m-top">
                <div className="col-sm-12 text-center">
                    <select value={this.state.retail} onChange={this.onRetailChanged}>
                        <option key='none' value='none'>Selecione</option>
                        {retailsSelectList.map((entry) =>
                            <option key={entry.customerId} value={entry.customerId}>{entry.customerName}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
    }

    cultiveComponents = (productTraceability) => {
        return <div className="panel-heading">
            <h3 className="panel-title">
                {productTraceability.cultive} / Nr. lote: {productTraceability.loteNumber}
            </h3>
            {this.displayProductValidity(productTraceability)}
            <h6 className="panel-title">
                Cód. de Rastreabilidade: {productTraceability.traceabilityCode}
            </h6>
            <h6 className="panel-title">
                Data de Plantio: {this.formatDate(new Date(productTraceability.plantingDate))}
            </h6>
            {this.displayDataColheita(productTraceability)}
            {/* <h6 className="panel-title">
                Data de Transporte: {this.formatDate(new Date(productTraceability.dispatchDate))}
            </h6> */}
        </div>
    }

    producerComponents = (productTraceability) => {
        const producerCityState = productTraceability.producerState === null || productTraceability.producerState === ''
            ? productTraceability.producerCity : productTraceability.producerCity + ' / ' + productTraceability.producerState;

        return <div className="col-sm-6" style={{ paddingLeft: 0 + 'em', marginBottom: 2 + 'em' }} >
            <div className="col-sm-12">
                <h4>Produtor</h4>
            </div>
            <div className="col-sm-12">
                {productTraceability.producer}
            </div>
            <div className="col-sm-12">
                {producerCityState}
            </div>
        </div>
    }

    customerComponents = (productTraceability) => {
        return <div className="col-sm-6" style={{ paddingLeft: 0 + 'em' }}>
            <div className="col-sm-12">
                <h4>Comerciante</h4>
            </div>
            <div className="col-sm-12">
                {productTraceability.customerName}
            </div>
            <div className="col-sm-12">
                {productTraceability.customerCity} / {productTraceability.customerState}
            </div>
        </div>
    }

    loadProcuderData = (search) => {
        if (search.u != null && search.d != null) {
            traceability_service.fetchProductTraceability(search.u, search.d, (data) => {
                if (data != null)
                    this.setState({
                        product: data,
                        initialCenter: { lat: data.producerLat, lng: data.producerLong }
                    })
                else {
                    this.setState({
                        product: {}
                    })
                }
            });
        } else
            if (search.u != null && search.c != null) {
                traceability_service.fetchCultiveTraceability(search.u, search.c, (data) => {
                    if (data != null) {
                        const distinctRetailList = [];
                        const map = new Map();
                        for (const item of data.docs) {
                            if (!map.has(item.data().customerId)) {
                                map.set(item.data().customerId, true);    // set any value to Map
                                distinctRetailList.push({
                                    customerId: item.data().customerId,
                                    customerName: item.data().customerName
                                });
                            }
                        }

                        this.setState({
                            retailsList: data.docs,
                            retailsSelectList: distinctRetailList,
                            showRetails: true,
                        })
                    }
                    else {
                        this.setState({
                            retailsList: null,
                        })
                    }
                });

            } else
                if (search.u != null) {
                    this.setState({
                        showForm: true,
                    })
                }
    }

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: {}
            });
        }
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });


    displayMarkers = () => {
        const productTraceability = this.state.product;
        return [
            <Marker key={productTraceability.customerLat} id={productTraceability.customerLat} name={productTraceability.customer} position={{
                lat: productTraceability.customerLat,
                lng: productTraceability.customerLong
            }} onClick={this.onMarkerClick}>
            </Marker>,
            <Marker key={productTraceability.producerLat} id={productTraceability.producerLat} name={productTraceability.producer} position={{
                lat: productTraceability.producerLat,
                lng: productTraceability.producerLong
            }} onClick={this.onMarkerClick}>
            </Marker>
        ];
    }

    displayMaps = () => {
        if (this.state.initialCenter != null) {

            const productTraceability = this.state.product;
            const triangleCoords = [
                { lat: Number(productTraceability.producerLat), lng: Number(productTraceability.producerLong) },
                { lat: Number(productTraceability.customerLat), lng: Number(productTraceability.customerLong) },
            ];


            const mapStyles = {
                width: '100%',
                height: '400px',
            };

            return <div className="row body" style={{ height: 28 + 'em', marginRight: 1 + 'em' }}>
                <div className="col-sm-12" >
                    <GMaps
                        google={this.props.google}
                        zoom={6}
                        style={mapStyles}
                        initialCenter={this.state.initialCenter}>

                        {this.displayMarkers()}
                        <Polyline
                            path={triangleCoords}
                            strokeColor="#0000FF"
                            strokeOpacity={0.8}
                            strokeWeight={2} />

                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                            onClose={this.onClose}
                        >
                            <div>
                                <h4>{this.state.activeMarker.name}</h4>
                            </div>
                        </InfoWindow>
                    </GMaps>
                </div>
            </div>
        }
    }

    displayAppointments = () => {
        const productTraceability = this.state.product;
        var counter = 0;

        if (productTraceability.appointments != null && productTraceability.appointments.length > 0) {
            return <div>
                <h3 className="panel-title">
                    Apontamentos
            </h3>
                <table className="table" >
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Data</th>
                            <th scope="col">Descrição</th>
                            {/* <th scope="col">Qtde</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {productTraceability.appointments.map((entry) =>
                            <tr key={counter++}>
                                <th scope="row">{counter}</th>
                                <td>{new Date(entry.date).toLocaleDateString('pt-BR')}</td>
                                <td>{entry.description}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        }
    }

    displayDataColheita = (productTraceability) => {
        if (productTraceability.harvestDate != null) {
            return <h6 className="panel-title">
                Data de Colheita: {this.formatDate(new Date(productTraceability.harvestDate))}
            </h6>
        }
    }

    displayProductValidity = (productTraceability) => {
        if (productTraceability.validity != null) {
            return <h6 className="panel-title">
                Validade: {productTraceability.validity}
            </h6>
        }
    }

    formatDate = (date) => {
        var monthNames = [
            "Janeiro", "Fevereiro", "Março",
            "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro",
            "Novembro", "Dezembro"
        ];

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return day + ' de ' + monthNames[monthIndex] + ' ' + year;
    }

    handleTraceabilityCodeChange = (event) => {
        this.setState({ traceabilityCode: event.target.value });
    }

    onRetailChanged = (event) => {
        this.setState({ retail: event.target.value });
        var retails = this.state.retailsList.filter(retail => retail
            .data().customerId === event.target.value);

        retails.sort((a, b) => new Date(a.data().harvestDate) - new Date(b.data().harvestDate));
        const data = retails[retails.length - 1].data();
        this.setState({
            product: data,
            showRetails: false,
            initialCenter: { lat: data.producerLat, lng: data.producerLong },
        });
    }

    handleSubmit = (event) => {

        const search = queryString.parse(this.props.location.search)

        if (this.state.traceabilityCode !== null && this.state.traceabilityCode.trim() !== '')
            traceability_service.fetchProductTraceabilityCode(search.u, this.state.traceabilityCode, (data) => {
                if (data != null)
                    this.setState({
                        product: data,
                        initialCenter: { lat: data.producerLat, lng: data.producerLong },
                        showForm: false
                    })
                else {
                    alert('Código de rastreabilidade inválido');
                }
            });
        else {
            alert('Código de rastreabilidade obrigatório');
        }
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAiw0Mb4wItEBy1Z8NQEgF7PNUqYdHu8sg'
})(ProducerTraceability);