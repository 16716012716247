import firebase from '../../Firebase';
import * as dateHelper from '../../utils/data_util';

export function fetchProductTraceability(userId, dispatcheId, onSuccess) {
    firebase.firestore().collection('user_dispatches').doc(userId).collection('dispatches').doc(dispatcheId)
        .get()
        .then(function (docSnap) {
            onSuccess(docSnap.data());
        });
}

export function fetchProductTraceabilityCode(userId, traceabilityCode, onSuccess) {
    firebase.firestore().collection('user_dispatches').doc(userId).collection('dispatches').where('traceabilityCode', '==', traceabilityCode)
        .get()
        .then(function (querySnap) {
            if (querySnap.docs.length > 0)
                onSuccess(querySnap.docs[0].data());
            else onSuccess(null);
        });
}

export function fetchCultiveTraceability(userId, cultiveId, onSuccess) {
    let latestDispatchDate = new Date()
    latestDispatchDate.setDate(latestDispatchDate.getDate() - 30);

    firebase.firestore().collection('user_dispatches').doc(userId).collection('dispatches')
        .where('cultiveId', '==', cultiveId)
        .where('dispatchDate', '>', dateHelper.isoDateString(latestDispatchDate))
        .get()
        .then(function (querySnap) {
            if (querySnap.docs.length > 0)
                onSuccess(querySnap);
            else onSuccess(null);
        });
}

