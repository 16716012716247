import { GoogleApiWrapper, InfoWindow, Map as GMaps, Marker } from 'google-maps-react';
import queryString from 'query-string';
import React from 'react';
import * as traceability_service from '../../domain/services/product_trace_service';
import logo from '../img/hortify-preto.png';
import './App.css';

class ProductTraceability extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            product: null,
            retail: '',
            retailsList: null,
            retailsSelectList: null,
            showingInfoWindow: false,  //Hides or the shows the infoWindow
            activeMarker: {},          //Shows the active marker upon click
            selectedPlace: {},
            initialCenter: null,
            showForm: false,
            showRetails: false,
            traceabilityCode: ''
        };
    }

    componentDidMount() {
        const search = queryString.parse(this.props.search)
        this.loadProductTrace(search);
    }

    render() {
        const showForm = this.state.showForm;
        const showRetails = this.state.showRetails;
        const retailsSelectList = this.state.retailsSelectList;
        const productTraceability = this.state.product;
        const search = queryString.parse(this.props.search)

        const isUrlOk = search.up != null;
        const isLoading = productTraceability === null;

        if (!isUrlOk)
            return <p>Url inválida</p>

        if (isLoading && !showForm && !showRetails)
            return <p>Carregando...</p>

        if (showForm) {
            return this.traceabilityCodeField()
        }

        if (showRetails && retailsSelectList != null) {
            return this.retailsSelectOptions(retailsSelectList)
        }

        if (!isLoading && productTraceability.traceabilityCode != null)
            return (
                <div className="container">
                    <div className="panel panel-default">
                        <div className="panel-heading">
                            <h3 className="panel-title">
                                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
                            </h3>
                        </div>

                        {this.productComponents(productTraceability)}

                        <div className="row" style={{ marginTop: 1.5 + 'em' }}>
                            {this.producerComponents(productTraceability)}
                            {this.retailComponents(productTraceability)}
                        </div>

                        {this.displayMaps()}
                    </div>
                </div>
            );
        else
            return <p>Código inválido!</p>
    }


    loadProductTrace = (search) => {
        if (search.up != null && search.dp != null) {
            traceability_service.fetchProductTraceability(search.up, search.dp, (data) => {
                if (data != null)
                    this.setState({
                        product: data,
                        initialCenter: { lat: data.customer.latitude, lng: data.customer.longitude }
                    })
                else {
                    this.setState({
                        product: {}
                    })
                }
            });
        } else
            if (search.up != null && search.p != null) {
                traceability_service.fetchProductTraceabilityByProductTrackCode(search.up, search.p, (data) => {
                    if (data != null) {
                        const distinctRetailList = [];
                        const map = new Map();
                        for (const item of data.docs) {
                            if (!map.has(item.data().customer.customerIdentifier)) {
                                map.set(item.data().customer.customerIdentifier, true);    // set any value to Map
                                distinctRetailList.push({
                                    customerId: item.data().customer.customerIdentifier,
                                    customerName: item.data().customer.name
                                });
                            }
                        }

                        this.setState({
                            retailsList: data.docs,
                            retailsSelectList: distinctRetailList,
                            showRetails: true,
                        })
                    }
                    else {
                        this.setState({
                            retailsList: null,
                        })
                    }
                });

            } else
                if (search.up != null) {
                    this.setState({
                        showForm: true,
                    })
                }
    }

    traceabilityCodeField = () => {
        return <div style={{ marginTop: 5.5 + 'em' }}>
            <div className="text-center">
                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
            </div>

            <div className="text-center">
                <label>
                    Por favor informe o código de rastreabilidade localizado na embalagem
          </label>
            </div>
            <div className="row justify-content-center m-top">
                <div className="col-sm-4">
                    <input className="form-control" value={this.state.traceabilityCode} onChange={this.handleTraceabilityCodeChange} type="text" />
                </div>
            </div>

            <div className="text-center m-top">
                <input type="button" className="btn btn-success" onClick={this.handleSubmit} value="Buscar" />
            </div>
        </div>
    }

    retailsSelectOptions = (retailsSelectList) => {
        return <div style={{ marginTop: 5.5 + 'em' }}>
            <div className="text-center">
                <img src={logo} alt="Logo" style={{ marginTop: 0.5 + 'em' }} width='222px' />
            </div>

            <div className="text-center">
                <label>
                    Por favor selecione o local onde o produto foi adquirido
                </label>
            </div>
            <div className="row justify-content-center m-top">
                <div className="col-sm-12 text-center">
                    <select value={this.state.retail} onChange={this.onRetailChanged}>
                        <option key='none' value='none'>Selecione</option>
                        {retailsSelectList.map((entry) =>
                            <option key={entry.customerId} value={entry.customerId}>{entry.customerName}</option>
                        )}
                    </select>
                </div>
            </div>
        </div>
    }

    productComponents = (productTraceability) => {
        return <div className="panel-heading">
            <h3 className="panel-title">
                {productTraceability.productName} / Nr. lote: {productTraceability.lote}
            </h3>
            {this.displayProductValidity(productTraceability)}
            <h6 className="panel-title">
                Cód. de Rastreabilidade: {productTraceability.traceabilityCode}
            </h6>
            <h6 className="panel-title">
                Data de embalagem: {this.formatDate(new Date(productTraceability.packingDate))}
            </h6>
        </div>
    }

    producerComponents = (productTraceability) => {
        return <div className="col-sm-6" style={{ paddingLeft: 0 + 'em', marginBottom: 2 + 'em' }} >
            <div className="col-sm-12">
                <h4>Produtor/Cultivos</h4>
            </div>

            {productTraceability.cropsHarvest.map((production) =>
                <div key={production.harvestId} >
                    <div className="col-sm-12">
                        {production.producer}
                    </div>
                    <div className="col-sm-12">
                        {production.cultive + ' / Colheita: ' + this.formatDate(new Date(production.dateHarvest))}
                    </div>
                    <div className="col-sm-12">
                        {'Lote: ' + production.numberLot}
                    </div>
                    <div className="col-sm-12">
                        -
                    </div>
                </div>
            )}
        </div>
    }

    retailComponents = (productTraceability) => {
        return <div className="col-sm-6" style={{ paddingLeft: 0 + 'em' }}>
            <div className="col-sm-12">
                <h4>Comerciante</h4>
            </div>
            <div className="col-sm-12">
                {productTraceability.customer.name}
            </div>
            <div className="col-sm-12">
                {productTraceability.customer.city} / {productTraceability.customer.state}
            </div>
        </div>
    }

    onClose = props => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                activeMarker: {}
            });
        }
    };

    onMarkerClick = (props, marker, e) =>
        this.setState({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });


    displayMarkers = () => {
        const productTraceability = this.state.product;
        const producer = productTraceability.cropsHarvest[0];
        const market = { url: require("../img/supermercado.png") };
        const farmer = { url: require("../img/trabalho.png") };

        const markers = [
            <Marker key={productTraceability.customer.latitude} icon={market} id={productTraceability.customer.latitude} name={productTraceability.customer.name} position={{
                lat: productTraceability.customer.latitude,
                lng: productTraceability.customer.longitude
            }} onClick={this.onMarkerClick}>
            </Marker>,
            <Marker key={producer.producerLat} icon={farmer} id={producer.producerLat} name={producer.producer} position={{
                lat: producer.producerLat,
                lng: producer.producerLong
            }} onClick={this.onMarkerClick}>
            </Marker>
        ];

        return markers
    }

    displayMaps = () => {
        if (this.state.initialCenter != null) {

            const mapStyles = {
                width: '100%',
                height: '400px',
            };

            return <div className="row body" style={{ height: 28 + 'em', marginRight: 1 + 'em' }}>
                <div className="col-sm-12" >
                    <GMaps
                        google={this.props.google}
                        zoom={6}
                        style={mapStyles}
                        initialCenter={this.state.initialCenter}>

                        {this.displayMarkers()}

                        <InfoWindow
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                            onClose={this.onClose}
                        >
                            <div>
                                <h4>{this.state.activeMarker.name}</h4>
                            </div>
                        </InfoWindow>
                    </GMaps>
                </div>
            </div>
        }
    }

    displayProductValidity = (productTraceability) => {
        if (productTraceability.validity != null) {
            return <h6 className="panel-title">
                Validade: {productTraceability.validity}
            </h6>
        }
    }

    formatDate = (date) => {
        var monthNames = [
            "Janeiro", "Fevereiro", "Março",
            "Abril", "Maio", "Junho", "Julho",
            "Agosto", "Setembro", "Outubro",
            "Novembro", "Dezembro"
        ];

        var day = date.getDate();
        var monthIndex = date.getMonth();
        var year = date.getFullYear();

        return day + ' de ' + monthNames[monthIndex] + ' ' + year;
    }

    handleTraceabilityCodeChange = (event) => {
        this.setState({ traceabilityCode: event.target.value });
    }

    onRetailChanged = (event) => {
        this.setState({ retail: event.target.value });
        var retails = this.state.retailsList.filter(retail => retail
            .data().customer.customerIdentifier === event.target.value);

        retails.sort((a, b) => new Date(a.data().packingDate) - new Date(b.data().packingDate));
        const data = retails[retails.length - 1].data();
        this.setState({
            product: data,
            showRetails: false,
            initialCenter: { lat: data.customer.latitude, lng: data.customer.longitude },
        });
    }

    handleSubmit = (event) => {
        const search = queryString.parse(this.props.search)

        if (this.state.traceabilityCode !== null && this.state.traceabilityCode.trim() !== '')
            traceability_service.fetchProductTraceabilityCode(search.up, this.state.traceabilityCode, (data) => {
                if (data != null)
                    this.setState({
                        product: data,
                        initialCenter: { lat: data.customer.latitude, lng: data.customer.longitude },
                        showForm: false
                    })
                else {
                    alert('Código de rastreabilidade inválido');
                }
            });
        else {
            alert('Código de rastreabilidade obrigatório');
        }
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAiw0Mb4wItEBy1Z8NQEgF7PNUqYdHu8sg'
})(ProductTraceability);