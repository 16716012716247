import firebase from 'firebase';


const config = {
  projectId: "minhaestufa-prod",
};

firebase.initializeApp(config);

firebase.firestore();

export default firebase;