
import queryString from 'query-string';
import React, { Component } from 'react';
import './App.css';
import MerchantTraceability from './merchant_traceability';
import ProducerTraceability from './producer_traceability';
import ProductTraceability from './product_traceability';

class App extends Component {
  render() {
    const search = queryString.parse(this.props.location.search)
    const isUrlOk = search.m != null || search.u != null || search.up != null;
    const isProductCrop = search.up != null
    if (isUrlOk) {
      if (search.u != null) {
        return <ProducerTraceability search={this.props.location.search} />
      } else if (search.m != null) {
        return <MerchantTraceability search={this.props.location.search} />
      } else if (isProductCrop) {
        return <ProductTraceability search={this.props.location.search} />
      }
    } else {
      return <p>Url inválida</p>
    }
  }
}

export default App;